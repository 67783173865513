html {
  position: relative;
  min-height: 100%;
  -webkit-user-select: none;
}

body {
  font-family: 'Lato', sans-serif;
  margin-bottom: 10px;
  -webkit-user-select: none;
}

h1 {
  color: #3c3124;
  font-family: 'Lato', sans-serif;
  font-size: 26px;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 32px;
}

h6 {
  color: #3c3124;
  /* font-family: 'Lato', sans-serif; */
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  /* line-height: 32px; */
}

div.sm {
  font-size: 10px;
}

.logo {
  transition: transform 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
  transform: translate(0, 0) scale(1) rotate(0deg);
  -webkit-transform: translate(0, 0) scale(1) rotate(0deg);
  -ms-transform: translate(0, 0) scale(1) rotate(0deg);
}

.logo-scale {
  transform: translate(0, 40px) scale(2) rotate(360deg);
  -webkit-transform: translate(0, 40px) scale(2) rotate(360deg);
  -ms-transform: translate(0, 40px) scale(2) rotate(360deg);
}

.image-pad-top {
  margin-top: 20px;
}

.container {
  /* margin-top: 22px; */
  margin-top: 0px;
}

.points-container {
  padding: 5px;
  padding-bottom: 0px;
  margin-top: 20px;
  /* background-color: #f6f2ea; */
  justify-content: space-around;
}

.zegels {
  padding-top: 15px;
  padding: 5px;
  justify-content: space-around;
}

.points-container img {
  max-width: 45px;
  max-height: 45px;
}

.active {
  -webkit-filter: grayscale(0) !important;
  -webkit-filter: grayscale(0%) !important;
  filter: grayscale(0) !important;
}

.inactive {
  -webkit-filter: grayscale(100%) !important;
  filter: grayscale(100) !important;
  -webkit-filter: grayscale(1) !important;
}

.modal-content {
  background-color: #f6f2ea !important;
  border-radius: 5px;
}

.centered {
  position: fixed;
  /* top: 50%; */
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  background-color: #f6f2ea !important;
}

.modal-content h1 {
  margin-top: 10px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  background-color: #3c3124;
}

.glyphicon-menu-hamburger {
  color: #3c3124;
  margin-top: 5px;
  margin-left: 10px;
  padding: 5px;
  border-radius: 5px;
  background-color: transparent;
  font-size: 30px;
}

.container.space-around {
  justify-content: space-around;
}

.z-index-9999 {
  z-index: 9999;
}

.z-index-1 {
  z-index: 1;
}