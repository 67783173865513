html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, 'Causten Bold', 'Causten Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

canvas {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
}

@font-face {
  font-family: 'causten-bold';
  src: url('fonts/Causten-Bold.otf') format('opentype'),
    url('fonts/Causten-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'causten-regular';
  src: url('fonts/Causten-Regular.otf') format('opentype'),
    url('fonts/Causten-Regular.woff2') format('woff2');;
  font-weight: normal;
  font-style: normal;
  font-display: block;
}