.topheader {
  background-color: transparent;
}
.bottomfooter {
  background-color:transparent;
}
.navbar {
  background-color: transparent;
}

.navbar-card {
  background-color: transparent;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar-end-bottom {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 12px;
  font-style: italic;
}

.navbar-end-top {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: right;
  margin-left: auto;
  text-align: right;
}

.navbar-item {
  color: #fff;
  background-color: transparent;
  z-index: 9999;
  border-width: 0;
}
.results--section {
  padding: 0px 0px;
  /* margin-top: -60px; */
  margin-top: 10px;
}
h1 {
  text-align: center;
  font-size: 30px;
}

.header-container {
  /* margin-top: 22px; */
  margin-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.hr-bottom {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

.logo-profile {
  transition: transform 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
  transform: translate(0, 0) scale(1) rotate(0deg);
  -webkit-transform: translate(0, 0) scale(1) rotate(0deg);
  -ms-transform: translate(0, 0) scale(1) rotate(0deg);
}

.navbar-link.is-active,
.navbar-link:hover,
a.navbar-item.is-active,
a.navbar-item:hover {
  background-color: transparent;
  color: #0a0a0a;
}
