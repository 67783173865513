html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, 'Causten Bold', 'Causten Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

canvas {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
}

@font-face {
  font-family: 'causten-bold';
  src: url(../../static/media/Causten-Bold.fdbd1403.otf) format('opentype'),
    url(../../static/media/Causten-Bold.402eea20.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'causten-regular';
  src: url(../../static/media/Causten-Regular.b0f79938.otf) format('opentype'),
    url(../../static/media/Causten-Regular.828d5c11.woff2) format('woff2');;
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.topheader {
  background-color: transparent;
}
.bottomfooter {
  background-color:transparent;
}
.navbar {
  background-color: transparent;
}

.navbar-card {
  background-color: transparent;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar-end-bottom {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 12px;
  font-style: italic;
}

.navbar-end-top {
  justify-content: right;
  margin-left: auto;
  text-align: right;
}

.navbar-item {
  color: #fff;
  background-color: transparent;
  z-index: 9999;
  border-width: 0;
}
.results--section {
  padding: 0px 0px;
  /* margin-top: -60px; */
  margin-top: 10px;
}
h1 {
  text-align: center;
  font-size: 30px;
}

.header-container {
  /* margin-top: 22px; */
  margin-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.hr-bottom {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

.logo-profile {
  transition: transform 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
  transform: translate(0, 0) scale(1) rotate(0deg);
  -webkit-transform: translate(0, 0) scale(1) rotate(0deg);
  -ms-transform: translate(0, 0) scale(1) rotate(0deg);
}

.navbar-link.is-active,
.navbar-link:hover,
a.navbar-item.is-active,
a.navbar-item:hover {
  background-color: transparent;
  color: #0a0a0a;
}

html {
  position: relative;
  min-height: 100%;
  -webkit-user-select: none;
}

body {
  font-family: 'Lato', sans-serif;
  margin-bottom: 10px;
  -webkit-user-select: none;
}

h1 {
  color: #3c3124;
  font-family: 'Lato', sans-serif;
  font-size: 26px;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 32px;
}

h6 {
  color: #3c3124;
  /* font-family: 'Lato', sans-serif; */
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  /* line-height: 32px; */
}

div.sm {
  font-size: 10px;
}

.logo {
  transition: transform 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
  transform: translate(0, 0) scale(1) rotate(0deg);
  -webkit-transform: translate(0, 0) scale(1) rotate(0deg);
  -ms-transform: translate(0, 0) scale(1) rotate(0deg);
}

.logo-scale {
  transform: translate(0, 40px) scale(2) rotate(360deg);
  -webkit-transform: translate(0, 40px) scale(2) rotate(360deg);
  -ms-transform: translate(0, 40px) scale(2) rotate(360deg);
}

.image-pad-top {
  margin-top: 20px;
}

.container {
  /* margin-top: 22px; */
  margin-top: 0px;
}

.points-container {
  padding: 5px;
  padding-bottom: 0px;
  margin-top: 20px;
  /* background-color: #f6f2ea; */
  justify-content: space-around;
}

.zegels {
  padding-top: 15px;
  padding: 5px;
  justify-content: space-around;
}

.points-container img {
  max-width: 45px;
  max-height: 45px;
}

.active {
  filter: grayscale(0) !important;
}

.inactive {
  filter: grayscale(100) !important;
  -webkit-filter: grayscale(1) !important;
}

.modal-content {
  background-color: #f6f2ea !important;
  border-radius: 5px;
}

.centered {
  position: fixed;
  /* top: 50%; */
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  background-color: #f6f2ea !important;
}

.modal-content h1 {
  margin-top: 10px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  background-color: #3c3124;
}

.glyphicon-menu-hamburger {
  color: #3c3124;
  margin-top: 5px;
  margin-left: 10px;
  padding: 5px;
  border-radius: 5px;
  background-color: transparent;
  font-size: 30px;
}

.container.space-around {
  justify-content: space-around;
}

.z-index-9999 {
  z-index: 9999;
}

.z-index-1 {
  z-index: 1;
}
